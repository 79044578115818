<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading">
      <v-card-title>
        تماس با "{{
          selectedData.user
            ? selectedData.user.first_name + " " + selectedData.user.last_name
            : selectedData.name
            ? selectedData.name
            : "نامشخص"
        }}"
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <small>نام</small>
            <h3 class="black--text" v-if="selectedData.name">
              {{ selectedData.name }}
            </h3>
            <h3 class="black--text" v-else-if="selectedData.user">
              {{
                selectedData.user.first_name + " " + selectedData.user.last_name
              }}
            </h3>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <small>ایمیل</small>
            <h3 class="black--text" v-if="selectedData.email">
              {{ selectedData.email }}
            </h3>
            <h3 class="black--text" v-else-if="selectedData.user">
              {{ selectedData.user.email }}
            </h3>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <small>شماره تلفن</small>
            <h3 class="black--text" v-if="selectedData.cellphone">
              {{ selectedData.cellphone }}
            </h3>
            <h3 class="black--text" v-else-if="selectedData.user">
              {{ selectedData.user.cellphone }}
            </h3>
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <h3>متن پیام</h3>
        <h4 class="mt-4 black--text">{{ selectedData.desc }}</h4>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style></style>
