<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
      <contact-us-content-modal
        ref="detailsModal"
        :selectedData="selectedData"
      />
      <v-col cols="12">
        <v-card class="moholand-shadow rounded-lg">
          <v-card-title>
            پیام های من
          </v-card-title>

          <div class="d-flex flex-wrap pa-4">
            <!-- <v-btn
              dark
              large
              class="rounded-lg mx-2 moholand-shadow my-auto"
              color="red darken-2"
              @click="onDelete"
            >
              حذف پیام
            </v-btn> -->
            <search-bar @onSubmit="onSearchSubmit" />
          </div>

          <MyDataTable
            :headers="headers"
            :items="items"
            :loading="loading"
            :pagesLength="pagesLength"
            @onPageChanged="GetList"
            :isEditEnabled="false"
            :isDeleteEnabled="false"
          >
            <template #middleButtonTag="{ row }">
              <v-btn
                class="mx-2"
                dark
                small
                color="primary"
                @click="onDetailsClick(row)"
              >
                <v-icon small dark>mdi-apps</v-icon>
                مشاهده
              </v-btn>
            </template>
            <template #contentTag="{ row }">
              <td>
                {{
                  row.name || row.user
                    ? row.user.first_name + " " + row.user.last_name
                    : "-"
                }}
              </td>
              <td>
                {{ row.cellphone || row.user ? row.user.cellphone : "-" }}
              </td>
              <td>{{ row.email || row.user ? row.user.email : "-" }}</td>
            </template>
          </MyDataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import ContactUsContentModal from "@/components/Panel/ContactUsContentModal.vue";
import SearchBar from "../../components/SearchBar.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    ContactUsContentModal,
    SearchBar,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/contacts/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: "user",
          conditions: this.conditions,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.searchData = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/categories/edit/" + item.id,
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    onSearchSubmit(name) {
      if (name.length) {
        this.searchData = this.searchData.filter((val) =>
          val.name.includes(name)
        );
      } else {
        this.searchData = this.items;
      }
    },
    deleteItem(item) {
      MyAxios.delete(`/contacts/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      searchData: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/contacts/index",
      pathParams: {},
      conditions: {
        vendor_id: this.$store?.state?.userData?.id || 0,
      },
      headers: [
        {
          text: "نام",
          align: "start",
          value: "name",
        },
        { text: "شماره تلفن", value: "cellphone" },
        { text: "ایمیل", value: "email" },
        { text: "عملیات", value: "desc" },
      ],
    };
  },
};
</script>

<style></style>
